import React from "react";
import { AuthProvider } from "@fitplan/context/lib-es/auth";
import { ConfigContext } from "@fitplan/context/lib-es/config";

import * as config from "./src/config";

import "./src/global/index.scss";
import "@fortawesome/fontawesome/styles.css";

export const shouldUpdateScroll = () => {
    const element = document.getElementById("___gatsby");
    element.scrollIntoView(true);
    return false;
};

export const wrapRootElement = ({ element }) => (
    <ConfigContext.Provider value={config}>
        <AuthProvider apiBaseUrl={config.apiBaseUrl}>
            {element}
        </AuthProvider>
    </ConfigContext.Provider>
);
