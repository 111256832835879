module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FitplanApp","short_name":"FitplanApp","start_url":"/","background_color":"#e6e6e6","theme_color":"#000000","display":"minimal-ui","icon":"src/data/images/icon.png","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"33179cf8989f1f29bb14535dc51b980e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://60aa11ec4a6f4d08a938bd3ced96c57f@sentry.io/1542433","environment":"production","enabled":true,"release":"e2e86ceea300f1a8dfc88cfcb8df96a61f6aa622","tags":{"sha":"e2e86ceea300f1a8dfc88cfcb8df96a61f6aa622","branch":"master","buildId":"61d48918703549528210cce7"}},
    },{
      plugin: require('../plugins/gatsby-plugin-custom-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-86409804-1","GTM-5JB6SG4","GTM-K87W9X5"],"gtagConfig":{"cookie_expires":0,"optimize_id":"GTM-PH4X4QM","custom_map":{"dimension2":"branch"}},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/404"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
