const env =
    process.env.GATSBY_ENV ||
    process.env.ACTIVE_ENV ||
    process.env.NODE_ENV ||
    "development";

const locale = process.env.LOCALE;

const cmsBaseUrl = environment =>
    ({
        development: "https://cmsstg.fitplanapp.com",
        stage: "https://cmsstg.fitplanapp.com",
        production: "https://cmsstg.fitplanapp.com",
    }[environment]);

const localeUrls = environment =>
    ({
        development: {
            en: "/",
            es: "/",
        },
        stage: {
            en: "https://stage-fitplan-website.netlify.com/",
            es: "https://stage-es-fitplan-website.netlify.com/",
        },
        production: {
            en: "https://www.fitplanapp.com",
            es: "https://es.fitplanapp.com",
        },
    }[environment]);

const apiBaseUrl = environment =>
    ({
        development:
            "https://z8mmzuc9l0.execute-api.us-east-1.amazonaws.com/dev/",
        stage: "https://z8mmzuc9l0.execute-api.us-east-1.amazonaws.com/dev/",
        production:
            "https://yeo5lurpvf.execute-api.us-east-1.amazonaws.com/prod/",
    }[environment]);

const getStripeUrl = environment =>
    ({
        development:
            "https://1bvtll6fc0.execute-api.us-east-1.amazonaws.com/dev/",
        stage: "https://1bvtll6fc0.execute-api.us-east-1.amazonaws.com/dev/",
        production:
            "https://dt9ny1gmxk.execute-api.us-east-1.amazonaws.com/prod/",
    }[environment]);

const fitplanUrl = environment =>
    ({
        // development: "http://localhost:8080/fitplan-server",
        development: "https://input.fitplanapp.com/fitplan-server",
        //development: "https://dev.fitplanapp.com/fitplan-server",
        stage: "https://input.fitplanapp.com/fitplan-server",
        production: "https://api2.fitplanapp.com/fitplan-server",
    }[environment]);

module.exports = {
    env,
    release: `${process.env.COMMIT_REF}`,
    apiBaseUrl: fitplanUrl(env),
    imageBaseUrl: process.env.GATSBY_IMAGE_BASE_URL,
    stripeProductId: process.env.GATSBY_STRIPE_PRODUCT_ID,
    stripePublicKey: process.env.GATSBY_STRIPE_PUBLIC_KEY,
    googleApiClientId: process.env.GATSBY_GOOGLE_API_CLIENT_ID,
    facebookAppId: process.env.GATSBY_FACEBOOK_APP_ID,
    fitplanApiLoginClientId: process.env.GATSBY_FITPLAN_API_LOGIN_CLIENT_ID,
    fitplanApiLoginClientSecret:
        process.env.GATSBY_FITPLAN_API_LOGIN_CLIENT_SECRET,
    fitplanClientId: process.env.FITPLAN_API_CLIENT_ID,
    fitplanClientSecret: process.env.FITPLAN_API_CLIENT_SECRET,
    appleSignInClientId: process.env.GATSBY_APPLE_SIGNIN_CLIENT_ID,
    appleSignInRedirectUri: process.env.GATSBY_APPLE_SIGNIN_REDIRECT_URI,
    branchLinkApiKey: "key_live_imChLlPczdU4GWxIDQTIjodawFeG67Zg",
    cmsUrl: cmsBaseUrl(env),
    languages: { en: "En", es: "Es" },
    urls: {
        root: `${fitplanUrl(env)}/`,
        base: `${fitplanUrl(env)}/web/`,
        token: `${fitplanUrl(env)}/oauth/token`,
        userLogin: `${fitplanUrl(env)}/website/user/login`,
        userSignup: `${fitplanUrl(env)}/website/user/signup`,
        getStripePlans: `${fitplanUrl(env)}/web/stripe/payment/plans`,
        submitPayment: `${fitplanUrl(env)}/web/stripe/submit/payment`,
        submitOneTimePayment: `${fitplanUrl(
            env
        )}/web/stripe/submit/onetime/payment`,
        getUserProfile: `${fitplanUrl(env)}/v2/user/profile`,
        profileChange: `${fitplanUrl(env)}/v2/user/profile/change`,
        googleLogin: `${fitplanUrl(env)}/v2/user/signup/google`,
        facebookLogin: `${fitplanUrl(env)}/v2/user/signup/facebook/2`,
        changePassword: `${fitplanUrl(env)}/v2/web/password/change`,
        forgotPassword: `${fitplanUrl(env)}/v2/web/password/forgot`,
        resetPassword: `${fitplanUrl(env)}/v2/web/password/reset`,
        contact: `${fitplanUrl(env)}/v2/email/contact`,
    },
    api: {
        base: apiBaseUrl(env),
        login: "login",
        user: "user",
        updateUser: "user/update",
        postMessage: "contact-us",
        signup: "signup",
        coupon: "coupon",
        updatePaymentMethod: "update-stripe",
        stripeToken: "stripe-token",
        cancelStripe: "cancel-stripe",
        resetPassword: "reset-password",
        facebook: "facebook",
        forgotPassword: "forgot-password",
        changePassword: "change-password",
    },
    stripe: {
        base: getStripeUrl(env),
        plans: "get-stripe-plans",
    },
    localeUrls: localeUrls(env),
    locale,
    SNAP_PIXEL_KEY: "1289adab-6711-4c0c-af43-a74d8770a644",
    isProduction: () => env === "production",
    trialBranchLink:
        locale === "es"
            ? "https://fitplan.app.link/uqve1zYYhZ"
            : "https://fitplan.app.link/uqve1zYYhZ",
    downloadBranchLink: "https://fitplan.app.link/appdownload",
    and: locale === "es" ? "y" : "and",
};
